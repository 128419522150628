<template>
    <div class="main">
        <div class="main-pc" v-if="isPhone == 2">
            <img class="head" :src="imgUrl('payment_logo_for_pc1', 'jpg')" />
            <div v-if="infoData" class="propLine">
                <div class="prop-titleLine">
                    <img :src="imgUrl('payment_for_pc_title_1_props')" />
                    <div class="prop-titleInfo">
                        完成至尊充值的用户即可获得天狼
                        <span style="color: #ffbc55; font-weight: 800">专属定制</span>
                        道具
                        <!-- <br />
            官方将在10月25日之后，在集市回购【甜茶萌兔】 -->
                    </div>
                </div>
                <!-- 头像框 -->
                <div class="prop-content">
                    <div class="prop-title">
                        完成至尊充值的用户可随机获得一款
                        <span>专属定制头像框</span>
                        &nbsp;&nbsp;&nbsp;每月更新18款，需在天天狼人APP内领取
                    </div>
                    <img :src="infoData.official_pc_url" alt="" class="show16-img" />
                    <!-- <div class="frameIcon">
                        <img v-if="infoData[0].pic_pc" class="avatar-img layer-1" :src="infoData[0].pic_pc" />
                        <img v-if="infoData[0].pic_mob" class="avatar-img layer-2" :src="infoData[0].pic_mob" />
                        <img v-if="infoData[0].pic_word" class="avatar-img layer-3" :src="infoData[0].pic_word" />
                    </div>
                    <div class="frameIcon frameImg-2">
                        <img v-if="infoData[1].pic_pc" class="avatar-img layer-1" :src="infoData[1].pic_pc" />
                        <img v-if="infoData[1].pic_mob" class="avatar-img layer-2" :src="infoData[1].pic_mob" />
                        <img v-if="infoData[1].pic_word" class="avatar-img layer-3" :src="infoData[1].pic_word" />
                    </div>
                    <div class="frameIcon frameImg-3">
                        <img v-if="infoData[2].pic_pc" class="avatar-img layer-1" :src="infoData[2].pic_pc" />
                        <img v-if="infoData[2].pic_mob" class="avatar-img layer-2" :src="infoData[2].pic_mob" />
                        <img v-if="infoData[2].pic_word" class="avatar-img layer-3" :src="infoData[2].pic_word" />
                    </div>
                    <div class="sale-num">{{ infoData[0].selled_show_num }}/{{ infoData[0].sell_num }}</div>
                    <div class="sale-num sale-num-2">{{ infoData[1].selled_show_num }}/{{ infoData[1].sell_num }}</div>
                    <div class="sale-num sale-num-3">{{ infoData[2].selled_show_num }}/{{ infoData[2].sell_num }}</div> -->
                    <img :src="imgUrl(`px498888`)" class="priceImg" />
                    <div class="buy-frame-btn" @click="clickRecharge(14)">
                        <img :src="imgUrl(`p19998btn`)" class="buyBtnImg" />
                    </div>
                    <div class="buy-frame-detail">
                        <div class="left-font" @click="showPCSeleceItem(true)">专属定制头像框领取流程</div>
                        <div class="right-font" @click="dialogState(true)">点击查看详情</div>
                    </div>
                </div>
            </div>
            <!-- 充值 -->
            <div class="rechargeLine">
                <div class="recharge-titleLine">
                    <img :src="imgUrl('payment_for_pc_title_2')" />
                    <div class="recharge-titleInfo">钻石可用于购买天天狼人的道具和礼物</div>
                </div>
                <div class="recharge-contentLine">
                    <div class="inputLine">
                        <div class="search-input">
                            <input
                                id="userNo"
                                type="tel"
                                class="userNoInput"
                                placeholder="请输入天狼数字ID"
                                v-model="userNo"
                                :oninput="showNickName()"
                                @focus="focusFun"
                            />
                            <!-- <div class="searchBtn" @click="clickSearch">查询</div> -->
                        </div>
                        <input
                            id="nickName"
                            type="text"
                            class="nickNameInput"
                            readonly="readonly"
                            placeholder="根据ID显示昵称确认充值是否正确"
                            v-model="nickName"
                        />
                    </div>
                    <div v-if="infoData" class="itemGrid">
                        <div
                            v-for="(item, index) in 9"
                            :key="index"
                            class="prop-item"
                            @click="clickRecharge(index + 1 == 9 ? 14 : index + 1)"
                        >
                            <img :src="imgUrl(getString(index + 1), 'png', index > 5 ? true : false)" />
                        </div>
                        <div class="god-emperor">
                            <div class="prop-item"></div>
                            <div class="prop-item" @click="handleClickBuyGodEmperor">
                                <img :src="imgUrl('payment_item_god_emperor')" />
                            </div>
                            <div class="prop-item" @click="clickRecharge(18)">
                                <img :src="imgUrl('payment_item_lettering_998')" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- footer -->
            <div style="margin-top: 30px; margin-bottom: 10px; font-weight: normal">
                <center>
                    <a href="https://beian.miit.gov.cn/" target="_blank">
                        <span style="color: white; font-size: 8px; margin-top: 8px">
                            增值电信业务经营许可证：辽B2-20170046
                        </span>
                    </a>
                    <a href="http://megatech.cc/officialWebsite/images/mega_zengzhidianxin.jpg" target="_blank">
                        <span
                            style="color: white; font-size: 8px; margin-top: 8px; margin-bottom: 15px; margin-left: 8px"
                        >
                            辽B2-20170046-2
                        </span>
                    </a>
                </center>
                <center>
                    <a
                        target="_blank"
                        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=21010302000476"
                        style="display: inline-block; text-decoration: none; height: 20px; line-height: 20px"
                    >
                        <img src="../../assets/icpIcon.png" style="float: left" />
                        <p
                            style="
                                color: white;
                                font-size: 8px;
                                float: left;
                                height: 20px;
                                line-height: 20px;
                                margin: 0px 0px 0px 5px;
                            "
                        >
                            辽公网安备 21010302000476号
                        </p>
                    </a>
                </center>
                <center>
                    <div style="color: white; font-size: 8px">
                        Copyright © 2016 - 2020 Mega Information Technology Co.,Ltd. All Rights Reserved
                    </div>
                </center>
            </div>
        </div>

        <mobilePage v-else :obj="infoData"></mobilePage>

        <!-- 定制流程 pc-->
        <div v-if="showDialog" class="detail-dialog">
            <div class="detail-content">
                <div class="closeBtn-pc" @click="dialogState(false)"></div>
                <img :src="imgUrl('payment_dialog_newqq', 'png', true)" class="detail-img" />
            </div>
        </div>

        <!-- 定制流程 mob-->
        <div v-if="showMobileDialog" class="m-detail-dialog detail-dialog" @touchmove.prevent>
            <div class="m-detail-content detail-content">
                <div class="closeBtn-mob" @click="mobileDialogState(false)"></div>
                <img :src="imgUrl('payment_dialog_for_phone_newqq', 'png', true)" class="m-detail-img detail-img" />
            </div>
        </div>

        <!-- 支付方式 -->
        <div v-if="showChooseWay" class="choose-way-dialog" @touchmove.prevent>
            <div class="dialog-content-m">
                <div class="dialog-title-m">请选择支付方式</div>
                <div
                    class="payaway-btn alipay-payaway-btn"
                    :class="[payAway == 1 ? 'payaway-alipay-s' : 'payaway-alipay']"
                    @click="payAway = 1"
                ></div>
                <div
                    class="payaway-btn"
                    :class="[payAway == 2 ? 'payaway-wechat-s' : 'payaway-wechat']"
                    @click="payAway = 2"
                ></div>
                <div class="opera-btn confirm-btn-m" @click="clickConfirmBtn">确定</div>
                <div class="opera-btn cancel-btn-m" @click="clickCancelBtn">考虑一下</div>
            </div>
        </div>

        <!-- 微信二维码支付 -->
        <div v-if="order_info" class="qr_code_dialog">
            <div class="qr_code_content">
                <div class="center_content">
                    <div class="top_line">
                        <div class="top_totle">微信支付</div>
                        <div class="top_close_btn" @click="order_info = null"></div>
                    </div>
                    <img :src="getQRcodeImg()" class="qr_code_img" />
                    <div class="pay_notice">扫描二维码付款</div>
                    <div class="gray_line"></div>
                    <div class="info_line">
                        <img :src="imgUrl('diamond')" class="diamond_img" />
                        <div class="diamond_num">x{{ order_info.diamond_num }}</div>
                        <div class="coast_num">￥{{ order_info.price }}.00</div>
                        <div class="opration_notice">支付</div>
                    </div>
                    <div class="search_btn" @click="searchOrderDetail">支付完成请点击</div>
                </div>
            </div>
        </div>
        <!-- 充值成功提示 -->
        <div v-if="payResult" class="choose-way-dialog" @touchmove.prevent>
            <div class="confirm-pay-dialog">
                <div style="width: 90%; color: black">
                    <div style="width: 100%; text-align: center; margin-bottom: 15px">购买成功</div>
                    <div style="font-size: 16px">
                        <div>商品名：{{ payResult.msg.name }}</div>
                        <div>订单号：{{ payResult.msg.out_trade_no }}</div>
                    </div>
                </div>
                <div class="confirm-pay-btn confirm-btn-m" @click="payResult = null">确定</div>
            </div>
        </div>
        <pcSelectItem v-if="PCChooseItem" :pcObj="infoData"></pcSelectItem>
        <pcOpeningEmperor v-if="showPcOpeningEmperor"></pcOpeningEmperor>
    </div>
</template>

<script>
import { isMobile, getParam } from "./../../utils/utils";
import { postInfo, getNickname, wechatNativePay, wechatH5Query, getUserios } from "./../../apis/officialWeb";
export default {
    name: "",
    data() {
        return {
            infoData: null,
            rechargeDic: {
                1: "6",
                2: "30",
                3: "68",
                4: "128",
                5: "328",
                6: "648",
                7: "2998",
                8: "6888",
                14: "19998",
                15: "29998"
            },
            showDialog: false,
            showMobileDialog: false,
            userNo: null,
            nickName: "",
            userNoCache: null,
            isPhone: 0,
            showChooseWay: false,
            index: null,
            payAway: 0,
            order_info: null,
            btn_close: false,
            payResult: null,
            PCChooseItem: false,
            showPcOpeningEmperor: false //展示神皇贵族页面
        };
    },
    components: {
        mobilePage: () => import("./mobilePage"),
        pcSelectItem: () => import("./pcSelectItem"),
        pcOpeningEmperor: () => import("./pcOpeningEmperor")
    },
    created() {
        //判断终端设备
        if (isMobile()) {
            // console.log("移动端");
            this.isPhone = 1;
        } else {
            // console.log("PC端");
            this.isPhone = 2;
            //判断是否有订单号,有订单号弹出选择界面
            let out_trade_no = getParam("out_trade_no");
            if (out_trade_no.indexOf("alipay") > -1) {
                if (out_trade_no.split("-")[1] == 16 || out_trade_no.split("-")[1] == 17) {
                    // let userNo = out_trade_no.split("-")[2]
                    // let phone = getCookie(userNo)
                    // let cookie = document.cookie;
                    this.handleClickBuyGodEmperor();
                } else if (out_trade_no.split("-")[1] == 14) {
                    // this.PCChooseItem = true;
                } else if (out_trade_no.split("-")[1] == 18) {
                    // this.PCChooseItem = true;
                }
            }
        }
        postInfo({
            isPhone: this.isPhone
        }).then((response) => {
            // console.log("response=>", response);
            this.infoData = response.msg;
            console.log(this.infoData.official_pc_url);
        });
    },
    methods: {
        showPCSeleceItem: function () {
            return;
            // this.PCChooseItem = true;
        },
        getQRcodeImg: function () {
            return `${process.env.VUE_APP_WECHAT_PAY_HOST}qrcode.php?data=${this.order_info.code_url}`;
        },
        searchOrderDetail: function () {
            // console.log("111");
            //校验订单是否支付完成
            wechatH5Query({
                out_trade_no: this.order_info.out_trade_no
            }).then((response) => {
                //console.log("wechatH5Query response=>", response);
                if (response.sign == 0) {
                    //{{`${payResult.msg.name}充值成功！订单号：${payResult.msg.out_trade_no}`}}
                    this.payResult = response;
                    this.order_info = null;
                } else {
                    window.alert(response.msg);
                }
            });
        },
        clickCancelBtn: function () {
            this.showChooseWay = false;
            this.payAway = 0;
        },
        clickConfirmBtn: function () {
            //小额充值
            if (this.payAway == 0) {
                return;
            }
            if (this.payAway == 1) {
                //拉起alipay
                getUserios({
                    userNo: this.userNo,
                    type: this.index
                }).then((response) => {
                    // console.log(response);
                    if (response.sign == "0") {
                        let div = document.createElement("div");
                        let msg =
                            response.msg.split("/alipay/")[0] +
                            "https://werewolf.53site.com/alipay/" +
                            response.msg.split("/alipay/")[1];
                        div.innerHTML = msg;
                        document.body.appendChild(div);
                        document.forms[0].submit();
                    } else {
                        window.alert(response.msg);
                    }
                });
            }
            if (this.payAway == 2) {
                if (this.btn_close) {
                    return;
                }
                this.btn_close = true;
                // console.log("调用微信支付");
                wechatNativePay({
                    userNo: this.userNo,
                    type: this.index
                }).then((response) => {
                    this.btn_close = false;
                    // console.log(response);
                    if (response.sign == 0) {
                        this.order_info = response.msg;
                        this.showChooseWay = false;
                    } else {
                        window.alert(response.msg);
                    }
                });
            }
        },
        getString: function (index) {
            switch (index) {
                case 1:
                    return "payment_item_one_for_pc_1";
                case 2:
                    return "payment_item_two_for_pc_1";
                case 3:
                    return "payment_item_three_for_pc_1";
                case 4:
                    return "payment_item_four_for_pc_1";
                case 5:
                    return "payment_item_five_for_pc_1";
                case 6:
                    return "payment_item_six_for_pc_1";
                case 7:
                    return "payment_item_seven_for_pc_1";
                case 8:
                    return "payment_item_eight_for_pc_1";
                case 9:
                    return `payment_item_20000_for_pc_1`;
            }
        },
        showNickName: function () {
            if (this.userNo && this.userNo.length > 15) {
                this.userNo = this.userNo.substring(0, 15);
            }
            if (!this.userNo) {
                return;
            }
            this.userNo = this.userNo.replace(/[^\d]/g, "");
            getNickname({
                userNo: this.userNo
            }).then((response) => {
                // console.log(response.msg);
                this.nickName = response.msg;
            });
            // console.log(this.userNo);
        },
        clickRecharge: function (index) {
            // console.log("点击了充值", index);
            if (!this.userNo) {
                return window.alert("请输入天狼数字ID");
            }
            if (!this.nickName) {
                return window.alert("请查询昵称确认充值是否正确");
            }
            this.index = index;
            let str = "";
            if (index == 18) {
                str = `确定要给 ID：${this.userNo} 昵称：${this.nickName} 购买至尊专属刻字服务吗？`;
            } else {
                str = `确定要给 ID：${this.userNo} 昵称：${this.nickName} 充值${this.rechargeDic[index]}元吗？`;
            }
            let result = confirm(str);
            if (result) {
                if (this.index > 6 && this.index != 18) {
                    //大额充值
                    if (this.index == 14) {
                        //TODO拉起Alipay，带回调。
                        getUserios({
                            userNo: this.userNo,
                            type: index
                        }).then((response) => {
                            // console.log(response);
                            if (response.sign == "0") {
                                let div = document.createElement("div");
                                let msg =
                                    response.msg.split("/alipay/")[0] +
                                    "https://werewolf.53site.com/alipay/" +
                                    response.msg.split("/alipay/")[1];
                                div.innerHTML = msg;
                                document.body.appendChild(div);
                                document.forms[0].submit();
                            } else {
                                window.alert(response.msg);
                            }
                        });
                    } else {
                        //普通拉起Alipay
                        getUserios({
                            userNo: this.userNo,
                            type: index
                        }).then((response) => {
                            // console.log(response);
                            if (response.sign == "0") {
                                let div = document.createElement("div");
                                let msg =
                                    response.msg.split("/alipay/")[0] +
                                    "https://werewolf.53site.com/alipay/" +
                                    response.msg.split("/alipay/")[1];
                                div.innerHTML = msg;
                                document.body.appendChild(div);
                                document.forms[0].submit();
                            } else {
                                window.alert(response.msg);
                            }
                        });
                    }
                } else {
                    this.showChooseWay = true;
                }
                // console.log("点击了确认充值");
            } else {
                // console.log("点击了取消充值");
            }
        },
        showChooseWayDialog: function (val) {
            this.showChooseWay = true;
        },
        clickSearch: function () {
            // console.log("查询用户昵称");
            if (this.userNo) {
                if (this.userNo == this.userNoCache) {
                    return;
                }
                this.userNoCache = this.userNo; //缓存userNo防止重复点击
                getNickname({
                    userNo: this.userNo
                }).then((response) => {
                    // console.log(response.msg);
                    this.nickName = response.msg;
                });
            } else {
                window.alert("请输入天狼数字ID");
            }
        },
        dialogState: function (val) {
            this.showDialog = val;
        },
        mobileDialogState: function (val) {
            this.showMobileDialog = val;
        },
        focusFun: function () {
            this.nickName = "";
        },
        handleClickBuyGodEmperor: function () {
            // console.log('点击了购买神皇按钮');
            this.showPcOpeningEmperor = true;
        }
    }
};
</script>

<style scoped lang="scss">
@import "../../style/main.scss";

img {
    pointer-events: none;
}

.main-pc {
    width: 960px;
    min-width: 960px;
    margin: 0 auto;

    // position: absolute;
    .head {
        width: 960px;
    }

    .propLine {
        width: 100%;
        height: 814px;

        .prop-titleLine {
            padding-top: 6px;
            width: 100%;
            height: 50px;
            display: flex;
            -webkit-display: flex;
            justify-content: space-between;
            -webkit-justify-content: space-between;
            align-items: center;
            -webkit-align-items: center;
        }

        .prop-titleInfo {
            text-align: end;
            font-size: 15px;
            color: white;
            font-weight: normal;
        }

        .prop-content {
            width: 960px;
            height: 764px;
            @include imgbg(imgOssUrl("frame_bg_pc.png"));
            position: relative;

            .prop-title {
                position: absolute;
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 13px;
                font-size: 15px;
                line-height: 26px;
                height: 26px;
                width: 759px;
                left: calc(50% - 379.5px);
                text-align: center;
                top: 6px;
                color: #fff;
                span {
                    color: #ffbc55;
                }
            }

            .show16-img {
                width: 940px;
                height: 436px;
                display: block;
                margin: 0 auto;
            }

            .frameIcon {
                width: 317px;
                float: left;
                margin-top: 103px;
                overflow: auto;
            }

            .frameImg-2 {
                margin-left: 5px;
            }

            .frameImg-3 {
                margin-left: 4px;
            }

            .sale-num {
                width: 317px;
                float: left;
                text-align: center;
                font-size: 27px;
                color: #ffbc55;
                margin-top: 32px;
                line-height: 27px;
            }

            .sale-num-2 {
                margin-left: 5px;
            }

            .sale-num-3 {
                margin-left: 4px;
            }

            .priceImg {
                width: 253px;
                margin-left: 350px;
                margin-top: 86px;
            }

            .buy-frame-btn {
                margin-left: 159px;
                margin-top: 45px;
            }

            .buyBtnImg {
                width: 445px;
            }

            .buy-frame-detail {
                width: 405px;
                margin-left: 180px;
                margin-top: 15px;
                font-size: 18px;
                line-height: 18px;
                display: flex;
                display: -webkit-flex;
                justify-content: space-between;
            }

            .left-font {
                color: white;
            }

            .right-font {
                color: #ffbc55;
            }
        }
    }

    .avatar-img {
        width: 317px;
        height: 265px;
        // position: absolute;
        display: block;
    }

    .layer-1 {
        z-index: 1;
    }

    .layer-2 {
        z-index: 2;
        margin-top: -265px;
    }

    .layer-3 {
        z-index: 3;
        margin-top: -265px;
    }

    .rechargeLine {
        width: 100%;
        // height: 763px;
    }

    .recharge-titleLine {
        padding-top: 6px;
        width: 100%;
        height: 50px;
        display: flex;
        -webkit-display: flex;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        align-items: center;
        -webkit-align-items: center;
    }

    .recharge-titleInfo {
        font-size: 15px;
        color: white;
        font-weight: normal;
    }

    .recharge-contentLine {
        width: 100%;
        // height: 713px;
        background-color: #370850;
        padding-top: 1%;
        padding-bottom: 1%;
    }

    .inputLine {
        width: 98%;
        height: 40px;
        margin-left: 1%;
        display: flex;
        justify-content: space-between;

        .search-input {
            width: 40%;
            height: 100%;
        }

        .userNoInput {
            width: 80%;
            height: 100%;
            padding: 1% 2.5%;
            background-color: #dfb8ff;
            color: #333;
            border: none;
            margin-left: 0px;
            margin-right: 0px;
            float: left;
        }

        .searchBtn {
            float: left;
            width: 19%;
            height: 100%;
            margin-left: 1%;
            background-color: rgb(255, 188, 85);
            color: rgb(147, 89, 0);
            text-align: center;
            line-height: 40px;
        }

        .nickNameInput {
            width: 30%;
            height: 100%;

            padding: 1% 2.5%;
            background-color: #dfb8ff;
            color: #333;
            border: none;
            float: right;
            text-align: center;
            margin-left: 0px;
            margin-right: 0px;
        }

        input::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
            margin: 0;
        }
    }

    .itemGrid {
        width: 98%;
        margin-left: 1%;
        display: flex;
        -webkit-display: flex;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        justify-content: space-between;
        -webkit-justify-content: space-between;
    }

    .prop-item {
        width: 33%;
        margin-top: 1%;

        img {
            width: 100%;
        }
    }

    .god-emperor {
        width: 100%;
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        -webkit-justify-content: space-between;
    }
}

.main-pc::-webkit-scrollbar {
    display: none;
}

.detail-dialog {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    min-width: 480px;
    height: 100%;
    z-index: 10;

    .detail-content {
        position: absolute;
        top: 50%;
        left: 52%;
        transform: translate(-50%, -50%);
        width: 50%;
        min-width: 480px;
        z-index: 2;
    }

    .closeBtn {
        width: 5%;
        min-width: 24px;
        margin-left: 95%;
    }

    .closeBtn-pc {
        width: 10%;
        height: 20%;
        position: absolute;
        margin-left: 81%;
        margin-top: 5%;
        // background-color: red;
    }

    .detail-img {
        width: 100%;
    }
}

.m-detail-dialog {
    min-width: 0;
    z-index: 11;

    .m-detail-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80vw;
        min-width: 0;
    }

    .closeBtn-mob {
        width: 20vw;
        height: 20vw;
        position: absolute;
        margin-left: 81%;
        margin-top: 7%;
    }
}

.choose-way-dialog {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    z-index: 10;
}

.dialog-content {
    // width: 50vw;
    width: 420px;
    // height: 77.3vw;
    padding-bottom: 36.96px;
    background-color: rgb(55, 9, 79);
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
}

.alipay-payaway-btn {
    margin-top: 33px;
}

.payaway-btn {
    width: 363px;
    // max-width: 370px;
    height: 70px;
    // max-height: 70px;
    margin: 0 auto;
    margin-top: 15px;
}

.payaway-alipay {
    @include imgbg(imgUrl("alipay.png"));
}

.payaway-alipay-s {
    @include imgbg(imgUrl("alipay-s.png"));
}

.payaway-wechat {
    @include imgbg(imgUrl("wechatpay.png"));
}

.payaway-wechat-s {
    @include imgbg(imgUrl("wechatpay-s.png"));
}

.opera-btn {
    width: 363px;
    height: 68px;
    border-radius: 15px;
    line-height: 68px;
    font-size: 19px;
    font-weight: bold;
    text-align: center;
    margin-top: 15px;
}

.confirm-btn-m {
    background-color: #ffbc55;
    color: #845000;
    margin-top: 16px;
}

.cancel-btn-m {
    background-color: #cbcbcb;
}

.dialog-content-m {
    width: 400px;
    // height: 77.3vw;
    padding-bottom: 21px;
    padding-top: 50px;
    background-color: white;
    border-radius: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    align-content: flex-start;
    -webkit-align-content: flex-start;
    justify-content: center;
    -webkit-justify-content: center;
}

.dialog-title-m {
    color: #424242;
    font-size: 22px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
}

.qr_code_dialog {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    z-index: 10;
}

.qr_code_content {
    width: 400px;
    background-color: white;
    border-radius: 10px;
    // height: 450px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    padding: 25px 20px;
}

.center_content {
    // background-color: aqua;
    width: 100%;
    height: 100%;
}

.top_line {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.top_totle {
    font-size: 20px;
}

.top_close_btn {
    font-size: 30px;
    margin-top: -10px;
}

.top_close_btn::after {
    content: "\2715";
    position: relative;
}

.qr_code_img {
    width: 220px;
    height: 220px;
    margin: 0 70px;
}

.pay_notice {
    width: 100%;
    text-align: center;
    font-size: 15px;
    color: #797979;
}

.gray_line {
    width: 100%;
    height: 1px;
    background-color: #b6b6b6;
    margin-top: 21px;
}

.info_line {
    width: 100%;
    height: 36px;
    margin-top: 19px;
    font-size: 20px;
    color: #000000;
}

.diamond_img {
    width: 41px;
    float: left;
}

.diamond_num {
    float: left;
    line-height: 36px;
    font-weight: 100;
    margin-left: 12px;
}

.opration_notice {
    font-weight: 400;
    float: right;
    margin-right: 33px;
    line-height: 36px;
}

.coast_num {
    font-weight: 400;
    float: right;
    font-size: 18px;
    line-height: 36px;
}

.search_btn {
    width: 200px;
    height: 50px;
    background-color: #ffbc55;
    margin-left: 80px;
    margin-top: 10px;
    border-radius: 10px;
    color: #845000;
    font-size: 18px;
    font-weight: 400;
    line-height: 50px;
    text-align: center;
}

.confirm-pay-dialog {
    font-size: 20px;
    padding-top: 20px;
    width: 400px;
    padding-bottom: 20px;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    align-content: flex-start;
    -webkit-align-content: flex-start;
    justify-content: center;
    -webkit-justify-content: center;
}

.confirm-pay-btn {
    width: 200px;
    height: 50px;
    background-color: #ffbc55;
    border-radius: 10px;
    color: #845000;
    font-size: 18px;
    font-weight: 400;
    line-height: 50px;
    text-align: center;
}
</style>
