import { request } from "../utils/request";

let ATV_NAME_TEMP = "officialWeb";
if (process.env.VUE_APP_MODE == "beta") {
    ATV_NAME_TEMP += "Beta";
}
//导出活动名
export const ATV_NAME = ATV_NAME_TEMP;
let WECHAT_PAY_PHP_URL = process.env.VUE_APP_WECHAT_PAY_HOST;

//信息
export async function postInfo(params) {
    // getAvatarFrameMsgNewV2.php
    return request(`getAvatarFrameMsgNewBox.php`, {
        method: "POST",
        body: params
    });
}
export async function getNickname(params) {
    return request(`getNickname.php`, {
        method: "POST",
        body: params
    });
}
//拉起支付宝支付
export async function getUser(params) {
    return request(`getUser_ios.php`, {
        method: "POST",
        body: params
    });
}
export async function getUser_office_json(params) {
    return request(`getUser_office_json.php`, {
        method: "POST",
        body: params
    });
}
//H5下单接口
export async function wechatH5Pay(params) {
    return request(`${WECHAT_PAY_PHP_URL}wechatH5Pay.php`, {
        method: "POST",
        body: params,
        isHaveBase: true
    });
}
//订单查询
export async function wechatH5Query(params) {
    return request(`${WECHAT_PAY_PHP_URL}wechatH5Query.php`, {
        method: "POST",
        body: params,
        isHaveBase: true
    });
}
//JS下单接口
export async function wechatJsPay(params) {
    return request(`${WECHAT_PAY_PHP_URL}wechatJsPay.php`, {
        method: "POST",
        body: params,
        isHaveBase: true
    });
}
//h5 微信二维码
export async function wechatNativePay(params) {
    return request(`${WECHAT_PAY_PHP_URL}wechatNativePay.php`, {
        method: "POST",
        body: params,
        isHaveBase: true
    });
}
//选择头像框接口
export async function selectFrame(params) {
    return request(`${WECHAT_PAY_PHP_URL}2wSelect.php`, {
        method: "POST",
        body: params,
        isHaveBase: true
    });
}
//根据环境区分php名称
let VUE_APP_ALIPAY_PHP = process.env.VUE_APP_ALIPAY_PHP;
export async function getUserios(params) {
    return request(`${VUE_APP_ALIPAY_PHP}`, {
        method: "POST",
        body: params
    });
}

const HTTP_GATEWAY = "/HttpGateway/goProxyHandler.php?server=go-api";
//获取神皇信息
export async function godEmperorInfo(params) {
    return request(`${HTTP_GATEWAY}&path=/pay/godEmperorInfo`, {
        method: "POST",
        body: params,
        isHaveBase: true
    });
}

//支付成功展示
export async function successShow(params) {
    return request(`${HTTP_GATEWAY}&path=/pay/successShow`, {
        method: "POST",
        body: params,
        isHaveBase: true
    });
}
